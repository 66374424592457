import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dashboard from '@mui/icons-material/Dashboard';
import User from '@mui/icons-material/People';
import Songs from '@mui/icons-material/LibraryMusic';
import News from '@mui/icons-material/Announcement';

// import Cloud from '@mui/icons-material/Cloud';
import { Link } from "react-router-dom";

export default function IconMenu() {
  return (
    <Paper sx={{ zIndex: "10", width: 250, height: '100%', maxWidth: '100%', position: "fixed", top: "60px", left: "0" }}>
      <MenuList>
      <Link style={{textDecoration: "none", color: "black"}}to="/">
        <MenuItem>
          <ListItemIcon>
            <Dashboard fontSize="small" />
          </ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
         

        </MenuItem></Link> 
        <Link style={{textDecoration: "none", color: "black"}} to="/users">
        <MenuItem>
          <ListItemIcon>
            <User fontSize="small" />
          </ListItemIcon>

          <ListItemText>Users</ListItemText>

        </MenuItem></Link>
        <Link style={{textDecoration: "none", color: "black"}} to="/songs">
        <MenuItem>
          <ListItemIcon>
            <Songs fontSize="small" />
          </ListItemIcon>
          

          <ListItemText>Songs</ListItemText>
        
        </MenuItem></Link>
        <Link style={{textDecoration: "none", color: "black"}} to="/news">
        <MenuItem>
          <ListItemIcon>
            <News fontSize="small" />
          </ListItemIcon>
          

          <ListItemText>News</ListItemText>
        
        </MenuItem></Link>
        <Divider />
      
      </MenuList>
    </Paper>
  );
}
