import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {Formik} from 'formik'
import * as yup from 'yup';
import { storage } from "utils/nhost";
import { gql, useMutation } from "@apollo/client";
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import './add_news.css';
// import Button1 from '@mui/material/Button';

const schema = yup.object().shape({
  songName: yup.string().required(),
  songDur: yup.string().required(),
  songDes: yup.string().required(),

  file: yup.mixed().required(),
  terms: yup.bool().required().oneOf([true], 'terms must be accepted'),
});


const INSERT_NEWS = gql`
  mutation($news_title: news_insert_input!) {
    insert_news(objects: [$news_title]) {
      affected_rows
    }
  }
`;
// const INSERT_DURATION = gql`
//   mutation($song_duration: String!) {
//     insert_song(objects: [$song_duration]) {
//       affected_rows
//     }
//   }

// `;


export default function AddNews() {


    const [newsTitle, setTitle] = useState('');
    const [newsLink, setLink] = useState('');
    const [newsDescript, setDescript] = useState('');
    
    const [storeCover, setCover] = useState(null);

    const [uploadNews] = useMutation(INSERT_NEWS);
    const [uploadCompleted, setUploadCompleted] = useState(0);
    const [uploadState, setUploadState] = useState("");

    

    // rerender UI every 5 sec to update 'created at' column
 

  
    function refreshPage() {
      window.location.reload(false);
    }
    
    function addCover(e) {
      setCover(e.target.files[0]);
    }
    
    const [open, setOpen] = useState(false);
    const [openSong, setOpenSong] = useState(false);
  
  
    const handleClickSong = () => {
      setOpenSong(true);
    };

  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleCloseSong = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSong(false);
    };
    async function upload(e) {
      e.preventDefault();

      try {
         await uploadNews({
          variables: {
            news_title: {
              news_title: newsTitle,
              news_cover: `/songs/${storeCover.name}`,
              news_link: newsLink,
              news_description: newsDescript,
            },
        
          },
        });
          
            await setUploadState('UPLOADING');
            await storage.put(`/songs/${storeCover.name}`, storeCover, null, (d) => {
                setUploadCompleted((d.loaded / d.total) * 100);});
        


      } catch (error) {
        console.log({error});
        return alert('something went wrong!!')

      }
      setCover(null);
      setDescript('');
      setTitle('');
      setLink('');
      setUploadState('');
      addButtonFunc();
      handleClickSong();
      refreshPage();


    }

    const [addForm, setFormDisplay] = useState('none');
    const [addButton, setButtonDisplay] = useState('block');

    let valueForm = 'block';
    
    
    function addFormFunc() {
      if ('none') {
        setFormDisplay(valueForm);
        setButtonDisplay('none');

      } else {
        console.log('nothing to do here');
      }
    }
    function addButtonFunc() {
      if ('none') {
        setButtonDisplay('block');
        setFormDisplay('none');
      } else {
        console.log('nothing to do here');
      }
    }

    function addButtonFuncReload() {
      if ('none') {
        setButtonDisplay('block');
        setFormDisplay('none');
        refreshPage();
      } else {
        console.log('nothing to do here');
      }
    }
    
      return (
        <div >
      
      <Fab color="primary" 
          aria-label="add" 
          style={{position: "fixed", right: "80px", display: `${addButton}`, zIndex: "2"}}
          onClick={addFormFunc}


    >
        <AddIcon />
      </Fab>
      <div>

            <Snackbar style={{marginTop: "50px"}}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={open} 
                autoHideDuration={3000} 
                onClose={handleClose}>
              <Alert 
                onClose={handleClose} 
                severity="success" 
                sx={{ width: '100%' }}>
               News wurde erfolgreich gelöscht...
              </Alert>
            </Snackbar>
         </div>
         <div>
            <Snackbar style={{marginTop: "50px"}}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={openSong} 
                autoHideDuration={3000} 
                onClose={handleCloseSong}>
              <Alert 
                onClose={handleCloseSong} 
                severity="success" 
                sx={{ width: '100%' }}>
               News wurde erfolgreich angelegt...
              </Alert>
            </Snackbar>
         </div>
        <Formik
       
     
          validationSchema={schema}
          onSubmit={console.log}
          initialValues={{
            songName: '',
            songDuration: '',
            songDescription: '',
            city: '',
         
            file: null,
            terms: false,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form className="addForm" noValidate   style={{display: `${addForm}`, width: "800px", marginLeft: "350px", marginTop: "100px"}}>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label>News Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="songName"
                    value={newsTitle}
                    onChange={(e) => setTitle(e.target.value)}
                    isValid={touched.songName && !errors.songName}
                  />
                  <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationFormik102"
                  className="position-relative"
                >
                  <Form.Label>News Link</Form.Label>
                  <Form.Control
                    type="text"
                    name="songDur"
                    value={newsLink}
                    onChange={(e) => setLink(e.target.value)}
                    isValid={touched.songDur && !errors.songDur}
                  />
    
                  <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                </Form.Group>
               
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="validationFormik103"
                  className="position-relative"
                >
                  <Form.Label>News Description</Form.Label>
                  <Form.Control
                    as="textarea"

                    name="songDes"
                    value={newsDescript}
                    onChange={(e) => setDescript(e.target.value)}
                    isInvalid={!!errors.city}
                    style={{height: "200px"}}
                  />
    
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
                
               
              </Row>
              
             
              {/* <Form.Group className="position-relative mb-3">
              <label htmlFor="contained-button-file" style={{display: "block", marginBottom: "20px"}}>
        <Input  id="contained-button-file"  type="file" name="file" onChange={addSong} />
        <Button variant="contained"  component="span">
          Upload Song
        </Button>

      </label>
      </Form.Group>
      <Form.Group className="position-relative mb-3">

      <label htmlFor="contained-button-file" style={{display: "block",  marginBottom: "20px"}}>
        <Input  id="contained-button-file"  type="file" name="file" onChange={addCover} />
        <Button variant="contained"  component="span">
          Upload Cover
        </Button>

      </label>
      </Form.Group> */}

              <Form.Group className="position-relative mb-3">
                <Form.Label>News Cover</Form.Label>
                <Form.Control
                  type="file"
                  required
                  name="file"
                  onChange={addCover}
                  isInvalid={!!errors.file}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.file}
                </Form.Control.Feedback>
              </Form.Group>
              {uploadState === "UPLOADING" && (
                  <div className="uploading-progress" >
                    {/* {uploadCompleted} % */}
                    <LinearProgress  style={{marginTop: "20px", marginBottom: "20px"}} variant="determinate" value={uploadCompleted} />
                  </div>
                )}
              <Button  onClick={upload} >Speichern</Button>
              <Button style={{marginLeft: "20px", backgroundColor: "red", border: "none"}} onClick={addButtonFuncReload} >abbrechen</Button>

            </Form>
               
          )}
        </Formik>
    
        </div>
      );
    }
    
