import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
// import CircularProgress from '@mui/material/CircularProgress';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
import './songs.css';
// import AddSong from '../add_song/add_song';
import SongList from '../songList/songList';
// const GET_TODOS = gql`
//   subscription {
//     song {
//       id
//       created_at
//       song_name
//     }
//   }
// `;



const INSERT_TODO = gql`
  mutation($song_name: song_insert_input!) {
    insert_song(objects: [$song_name]) {
      affected_rows
    }
  }
`;

// const DELETE_SONG = gql`
// mutation DeleteDoneTodos ($song_name: String!) {
//   delete_song(where: { song_name: { _eq: $song_name}}) {
//     affected_rows
//   }
// }
// `
// ;
function Songs() {



    // const [deleteSong] = useMutation(DELETE_SONG);
    // const { data, loading } = useSubscription(GET_TODOS);
    const [insertTodo] = useMutation(INSERT_TODO);
    const [todoName, setTodoName] = useState("");
   
    const [open, setOpen] = useState(false);
    const [openSong, setOpenSong] = useState(false);
    
  
    // const handleClick = () => {
    //   setOpen(true);
    // };
    const handleClickSong = () => {
      setOpenSong(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleCloseSong = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSong(false);
    };
  
    
    // if (loading) {
    //   return <div style={{position: "absolute", top: "50%", left: "50%" , transform: "translate(-50%, -50%)"}}><CircularProgress />
    //   </div>;
    // }
  
  
    return (

      <div className="container">
              <SongList />

            
      <div style={{width: "250px", height: "100%", left: "0",  position: "absolute", gridColumn: "1 / 2", gridRow: "1 / 2"}}></div>

      <div style={{width: "100%",  position: "absolute", gridColumn: "2", gridRow: "1 / 2"}}>
      {/* <AddSong /> */}

    <div style={{width: "100%", margin: "0 auto"}} >
      <div>

          <div>

            <Snackbar style={{marginTop: "50px"}}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={open} 
                autoHideDuration={3000} 
                onClose={handleClose}>
              <Alert 
                onClose={handleClose} 
                severity="success" 
                sx={{ width: '100%' }}>
               Song wurde erfolgreich gelöscht...
              </Alert>
            </Snackbar>
         </div>
         <div>
            <Snackbar style={{marginTop: "50px"}}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={openSong} 
                autoHideDuration={3000} 
                onClose={handleCloseSong}>
              <Alert 
                onClose={handleCloseSong} 
                severity="success" 
                sx={{ width: '100%' }}>
               Song wurde erfolgreich angelegt...
              </Alert>
            </Snackbar>
         </div>

        <div className="songs_create" style={{display: "none"}}>
          <form 
            onSubmit={async (e) => {
              e.preventDefault();
  
              try {
                await insertTodo({
                  variables: {
                    song_name: {
                      song_name: todoName,
                    },
                  },
                });
              } catch (error) {
                console.error(error);
                return alert("Error creating todo");
              }
              handleClickSong();
              setTodoName("");
            }}
          >
            <input
              type="text"
              placeholder="song"
              value={todoName}
              onChange={(e) => setTodoName(e.target.value)}
            />
            <button disabled={!todoName}>Create Song</button>
          </form>
            
        </div>
        {/* <div className="list_songs">
          
        {!data ? (
          "no data"
        ) : (
          <ul >
            {data.song.map((todo) => {
              return <li className="songs" key={todo.id}><p className="title_song">{todo.song_name}</p><IconButton  className="close" aria-label="delete" size="large" onClick= {async (e) => {
                e.preventDefault();
               
                try {
                  
                 
  
                      await deleteSong({
                   
                        variables: {
                          
                            song_name: todo.song_name
                          
                        },
                      });
                      handleClick();
  
                   
                
                   
  
                  
                } catch (error) {
                  console.error(error);
                  return alert("Error creating todo");
                }
  
              }}>
              <DeleteIcon />
            </IconButton>
           </li>;
            })}
     
          </ul>
          
          
        )}
        </div>       */}

      </div>

      </div>
        </div>
       
        </div>
    );
  }
  
  export default Songs;