import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "utils/nhost";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from './schallpause_logo.png';
import "./login.css";
export function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });



 
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  async function handleSubmit(e) {
    e.preventDefault();

    // login
    try {
      await auth.login({email, password});
    } catch (error) {
      handleClick();
      console.error(error);
      return;
    }

    // redirect back to `/`
    history.push("/");
  }

  return (
    <div className="loginbox" >
        <div className="header1">
            <img src={Logo} alt="Schallpause Logo" />
        <h1 className="header2">Schallpause backend LOGIN</h1>

        </div>
      <div>
          <Snackbar 
              anchorOrigin={{vertical: "top", horizontal: "right"}}
              open={open} 
              autoHideDuration={3000} 
              onClose={handleClose}>
            <Alert 
              onClose={handleClose} 
              severity="error" 
              sx={{ width: '100%' }}>
             E-Mail oder Passwort stimmen nicht überein!!
            </Alert>
          </Snackbar>
       </div>
       <Box onSubmit={handleSubmit}
      component="form"
     
      noValidate
      autoComplete="off"
    >
      <div>
       
      <FormControl  sx={{
        width: 300,
        backgroundColor: "white",
      }} variant="standard">
     <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
          <Input 
            fullWidth
            id="standard-adornment-email"
            type={email}
            value={email}
            autoComplete= "on"
            onChange={(e) => setEmail(e.target.value)}            
            endAdornment={
                <InputAdornment position="end">
                
                </InputAdornment>
              }
          />
               </FormControl>

      </div>
      <FormControl  sx={{
        width: 300,
        backgroundColor: "white",
      }} variant="standard">

          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input 
            fullWidth
            id="standard-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={password}
            autoComplete= "on"
            onChange={(e) => setPassword(e.target.value)}            
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        
        <button style={{backgroundColor: "transparent", width: "300px", border: "none", margin: "0 auto", display: "flex", marginTop: "50px", padding: "0"}}><Button style={{width: "300px", padding: "0", height: "30px",  margin: "0 auto"}} variant="contained">Login</Button></button>

    </Box>

    </div>
  );
}