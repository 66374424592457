import React, { useState} from "react";
import { gql, useSubscription, useMutation } from "@apollo/client";
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import './query_news.css';
import { storage } from "utils/nhost";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const GET_NEWS = gql`
  subscription {
    news {
        created_at
        created_from
        id
        news_description
        news_link
        news_title
        news_cover
      }
  }
`;
const DELETE_NEWS = gql`
mutation DeleteNews ($news_title: String!) {
  delete_news(where: { news_title: { _eq: $news_title}}) {
    affected_rows
  }
}
`
;



const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  


function NewsList() {
    const [expanded, setExpanded] = useState(false);

    // const handleExpandClick = () => {
    //   setExpanded(!expanded);
    // };

  const [deleteNews] = useMutation(DELETE_NEWS);

  const { data, loading } = useSubscription(GET_NEWS);


  
//   const [time, setTime] = useState(Date.now());

//   console.log(time);
//     useEffect(() => {
//       const interval = setInterval(() => setTime(Date.now()), 1000);
//       return () => {
//         clearInterval(interval);
//       };
//     }, []);
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleCloseSong = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSong(false);
    };
    const [open, setOpen] = useState(false);
    const [openSong, setOpenSong] = useState(false);
    
 const handleClickSong = () => {
      setOpenSong(true);
    };
    const handleClickSongError = () => {
      setOpen(true);
    };
 
  
  
  if (loading) {
    return <div style={{  position: "absolute", top: "50%", left: "50%" , transform: "translate(-50%, -50%)", gridColumn: "2", gridRow: "1 / 2"}}><CircularProgress /></div>
  }


  return (
    <div className="container"  >
    
    
<div>

<Snackbar style={{marginTop: "50px"}}
    anchorOrigin={{vertical: "top", horizontal: "right"}}
    open={open} 
    autoHideDuration={3000} 
    onClose={handleClose}>
  <Alert 
    onClose={handleClose} 
    severity="error" 
    sx={{ width: '100%' }}>
Scheinbar ist etwas schief gegangen!  </Alert>
</Snackbar>
</div>
<div>
<Snackbar style={{marginTop: "50px"}}
    anchorOrigin={{vertical: "top", horizontal: "right"}}
    open={openSong} 
    autoHideDuration={3000} 
    onClose={handleCloseSong}>
  <Alert 
    onClose={handleCloseSong} 
    severity="success" 
    sx={{ width: '100%' }}>
   Song wurde erfolgreich gelöscht...
  </Alert>
</Snackbar>
</div>
{!data ? 
 'no data' :

          <ul style={{display: "flex", marginTop: "100px"}}>
            {data.news.map((news) => {
              return <li style={{ marginTop: "150px", backgroundColor:"transparent", display: "flex"}} className="songs" key={news.id}><Card style={{height: "auto" }}sx={{ minWidth: 300, maxWidth: 345 }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    AD
                  </Avatar>
                }
                
                title={news.news_title}
                subheader={news.created_at}
              />
              <CardMedia
                component="img"
                height="194"
                image={`https://backend-03655200.nhost.app/storage/o${news.news_cover}`}
                alt="Paella dish"
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
              
                     <a target="_blank" rel="noreferrer" href={news.news_link}>More Infos</a> 
                
                </Typography>
              </CardContent>
              <CardActions disableSpacing >
               
                <ExpandMore
                  expand={expanded}
                  onClick={ 
                     function handleExpandClick1()  {
                    setExpanded(!expanded);
                  }}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                 
                  <Typography paragraph>
                 
                     <p>{news.news_description}</p>
                         
                </Typography>
               
        
                </CardContent>
              </Collapse>
              <IconButton  color="error" style={{position: "relative",  left: "20px", bottom: "0px", width: "50px", height: "50px"}} className="close" aria-label="delete" size="large" onClick= {async (e) => {
                  e.preventDefault();
                  try {
                      
                        await deleteNews({
                            
                          variables: {
                            
                              news_title: news.news_title
                            
                          },
                        });
                        await storage.delete(news.news_cover);
                    
                  } catch (error) {
                    console.error(error);
                    return handleClickSongError();
                  }
                  handleClickSong();
                }}>
                <DeleteIcon />
                </IconButton>
            </Card>
           </li>;
            })}
     
          </ul>
          
          
        }
        </div>       

 
  );
}

export default NewsList;