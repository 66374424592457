import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { green, blue, red } from '@material-ui/core/colors';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import SongIcon from '@material-ui/icons/LibraryMusic';
import CategoryIcon from '@material-ui/icons/QueueMusic';
import { gql, useSubscription} from "@apollo/client";
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CountSongs from '../count_songs/count_songs';


const GET_COUNTS = gql`
subscription  {
  users_aggregate {
    aggregate {
      count
    
    }
    
  }
}

`;


function TotalCustomers() {


const {data, loading} = useSubscription(GET_COUNTS);
  





  
  if (loading) {
    return <div style={{position: "absolute", top: "50%", left: "50%" , transform: "translate(-50%, -50%)"}}><CircularProgress />
    </div>;
  }

  return(
    <div style={{display: "inline-flex"}}>
  <Card  style={{marginTop: "100px", width: "300px",  marginLeft: "300px"}}>
    <CardContent >
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            ANZAHL USER
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
        {data.users_aggregate.aggregate.count}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: green[600],
              height: 56,
              width: 56
            }}
          >
            <PeopleIcon />
          </Avatar>
        </Grid>
      </Grid>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          pt: 2
        }}
      >
        {/* <ArrowUpwardIcon sx={{ color: green[900] }} />
        <Typography
          variant="body2"
          sx={{
            color: green[900],
            mr: 1
          }}
        >
          16%
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
        >
          Since last month
        </Typography> */}
      </Box>
    </CardContent>
  </Card>
  <Card style={{marginTop: "100px", width: "300px",  marginLeft: "50px"}}>
  <CardContent >
    <Grid
      container
      spacing={3}
      sx={{ justifyContent: 'space-between' }}
    >
      <Grid item>
        <Typography
          color="textSecondary"
          gutterBottom
          variant="h6"
        >
          ANZAHL SONGS
        </Typography>
        <Typography
          color="textPrimary"
          variant="h3"
        >
          <CountSongs />
        </Typography>
      </Grid>
      <Grid item>
        <Avatar
          sx={{
            backgroundColor: blue[600],
            height: 56,
            width: 56
          }}
        >
          <SongIcon />
        </Avatar>
      </Grid>
    </Grid>
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        pt: 2
      }}
    >
      {/* <ArrowUpwardIcon sx={{ color: blue[900] }} />
      <Typography
        variant="body2"
        sx={{
          color: blue[900],
          mr: 1
        }}
      >
        3%
      </Typography>
      <Typography
        color="textSecondary"
        variant="caption"
      >
        Since last month
      </Typography> */}
    </Box>
  </CardContent>
</Card>
<Card  style={{marginTop: "100px", width: "350px",  marginLeft: "50px"}}>
  <CardContent >
    <Grid
      container
      spacing={3}
      sx={{ justifyContent: 'space-between' }}
    >
      <Grid item>
        <Typography
          color="textSecondary"
          gutterBottom
          variant="h6"
        >
          ANZAHL KATEGORIEN
        </Typography>
        <Typography
          color="textPrimary"
          variant="h3"
        >
3        </Typography>
      </Grid>
      <Grid item>
        <Avatar
          sx={{
            backgroundColor: red[600],
            height: 56,
            width: 56
          }}
        >
          <CategoryIcon />
        </Avatar>
      </Grid>
    </Grid>
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        pt: 2
      }}
    >
      <ArrowUpwardIcon sx={{ color: red[900] }} />
      <Typography
        variant="body2"
        sx={{
          color: red[900],
          mr: 1
        }}
      >
        3%
      </Typography>
      <Typography
        color="textSecondary"
        variant="caption"
      >
        Since last month
      </Typography>
    </Box>
  </CardContent>
</Card>
</div>

  );
}
  


export default TotalCustomers;
