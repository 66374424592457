import { Redirect } from "react-router-dom";
import { useAuth } from "@nhost/react-auth";
import CircularProgress from '@mui/material/CircularProgress';

export function AuthGate({ children }) {
  const { signedIn } = useAuth();

  if (signedIn === null) {
    return <div style={{position: "absolute", top: "50%", left: "50%" , transform: "translate(-50%, -50%)"}}><CircularProgress />
    </div>;
  }

  if (!signedIn) {
    return <Redirect to="/login" />;
  }

  return children;
}