

  import { gql, useSubscription} from "@apollo/client";
  import React from 'react';
  import CircularProgress from '@mui/material/CircularProgress';
  
  
  
  const GET_SONGS = gql`
  subscription  {
    song_aggregate {
      aggregate {
        count
      
      }
      
    }
  }
  
  `;
  
  
  function CountSongs() {
  
  
  const {data, loading} = useSubscription(GET_SONGS);
    
  
  
  
  
  
    
    if (loading) {
      return <div style={{position: "absolute", top: "50%", left: "50%" , transform: "translate(-50%, -50%)"}}><CircularProgress />
      </div>;
    }
  
    return(
      <div style={{display: "inline-flex"}}>
   <p>{!data ? 'no data' : data.song_aggregate.aggregate.count}</p>
      
  </div>
  
    );
  }
    
  
  
  export default CountSongs;
  