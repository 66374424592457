import React from "react";
// import { gql, useSubscription } from "@apollo/client";

// import CircularProgress from '@mui/material/CircularProgress';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


// const GET_TODOS = gql`
//   subscription {
//     song {
//       id
//       created_at
//       song_name
//     }
//   }
// `;





function App() {



  // const { data, loading } = useSubscription(GET_TODOS);
 



  

  
  // if (loading) {
  //   return <div style={{position: "absolute", top: "50%", left: "50%" , transform: "translate(-50%, -50%)"}}><CircularProgress />
  //   </div>;
  // }


  return (

  
    <div style={{width: "100%"}}>
      
    </div>
  );
}

export default App;