import React, {useState} from "react";
import { gql, useSubscription } from "@apollo/client";
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import {auth} from 'utils/nhost';

import './users.css';

const GET_TODOS = gql`
  subscription {
    users {
      id
      created_at
      display_name
      username
      avatar_url
    }
  }
`;

const columns = [
  // { field: 'id', headerName: 'ID', width: 350 },

  { field: 'display_name', headerName: 'E-Mail', width: 180 },
  { field: 'username', headerName: 'Username', width: 180 },
  // { field: 'created_at', headerName: 'Created', width: 260 },


  
    
];





function Users() {



  const { data, loading } = useSubscription(GET_TODOS);
  const [email, setEmail] = useState('');

  async function resetPass() {

    try {
       await auth.requestPasswordChange(email);

    } catch (error) {
      alert('Reset failed!!!');
      return;
    }
    
  }

  
  
  if (loading) {
    return <div style={{  position: "absolute", top: "50%", left: "50%" , transform: "translate(-50%, -50%)", gridColumn: "2", gridRow: "1 / 2"}}><CircularProgress /></div>
  }


  return (
    <div className="container">
        <div style={{width: "250px", height: "100%",  position: "absolute", gridColumn: "1 / 2", gridRow: "1 / 2"}}></div>
        <div style={{ width: "100%", position: "absolute", gridColumn: "2", gridRow: "1 / 2"}}>
      <DataGrid style={{ width: '1100px', margin: "0 auto",  marginTop: "80px",   backgroundColor: "white"}}
        rows= {data.users}
        columns={columns}
        pageSize={2}
        rowsPerPageOptions={[3]}
        autoHeight
      />
    </div>
    <div style={{marginLeft: "500px", zIndex: "2", display: "none"}}>
    <input 
     type="text"
     value={email}
    onChange={(e) => setEmail(e.target.value)}

    />
   <button onClick={resetPass}>Reset Password</button>
   </div>
    </div>
  );
}

export default Users;