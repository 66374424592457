import React, { useState} from "react";
import { gql, useSubscription, useMutation } from "@apollo/client";
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import './songList.css';
import { storage } from "utils/nhost";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const GET_SONGS = gql`
  subscription {
    song {
      id
      created_at
      title
      duration
      artwork
      url
      song_category
      storage_url
      storage_artwork
      category {
        title
      }
    }
  }
`;
const DELETE_SONG = gql`
mutation DeleteDoneTodos ($title: String!) {
  delete_song(where: { title: { _eq: $title}}) {
    affected_rows
  }
}
`
;






function SongList() {


  const [deleteSong] = useMutation(DELETE_SONG);

  const { data, loading } = useSubscription(GET_SONGS);
  // const [time, setTime] = useState(Date.now());
  // console.log(time);
  //   useEffect(() => {
  //     const interval = setInterval(() => setTime(Date.now()), 1000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, []);
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleCloseSong = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSong(false);
    };
    const [open, setOpen] = useState(false);
    const [openSong, setOpenSong] = useState(false);
    
     if (loading) {
      return <div style={{position: "absolute", top: "50%", left: "50%" , transform: "translate(-50%, -50%)"}}><CircularProgress />
      </div>;
    }
  
    const handleClickSong = () => {
      setOpenSong(true);
    };
    const handleClickSongError = () => {
      setOpen(true);
    };
  const columns = [
    // { field: 'id', headerName: 'ID', width: 350 },
  
    { field: 'title', headerName: 'Song Name', width: 160 },
  
    { field: 'duration', headerName: 'Song Duration', width: 180 },
    { field: 'artwork', renderCell: (cellValues) => {
      return <a target="_blank" rel="noreferrer" href={`${cellValues.row.artwork}`}><img  alt="Song cover" style={{width: "50px"}} src={`${cellValues.row.artwork}`}/></a>;
    }, headerName: 'Song Cover', width: 170 },
    { field: 'url', renderCell: (cellValues) => {
      return <audio controls style={{padding: "10px", width: "400px"}}>
    <source src={`${cellValues.row.url}`} type="audio/ogg"></source>
  </audio>;
    }, headerName: 'Song', width: 350 },
    { field: 'category_name',renderCell: (cellValues) => {
      return <p>{cellValues.row.category.title}</p>;
    }, headerName: 'Kategorie', width: 170 },
    { field: 'loeschen',renderCell: (cellValues) => {
      return (
        <IconButton  color="error" style={{position: "relative",  left: "20px", top: "0px"}} className="close" aria-label="delete" size="large" onClick= {async (e) => {
          e.preventDefault();
         
          try {
            
           
  
                await deleteSong({
             
                  variables: {
                    
                      title: cellValues.row.title
                    
                  },
                });
                await storage.delete(cellValues.row.storage_url);
                await storage.delete(cellValues.row.storage_artwork);

  
             
          
             
  
            
          } catch (error) {
            console.error(error);
            return handleClickSongError();
          }
          handleClickSong();
        }}>
        <DeleteIcon />
      </IconButton>
      );
    }, headerName: 'Löschen', width: 200 },
  
  
  
  
  
  
    
      
  ];
  
  
  
  if (loading) {
    return <div style={{  position: "absolute", top: "50%", left: "50%" , transform: "translate(-50%, -50%)", gridColumn: "2", gridRow: "1 / 2"}}><CircularProgress /></div>
  }


  return (
    <div className="container">
    
      <DataGrid style={{ width: '1200px', marginLeft: "160px",   backgroundColor: "white", zIndex: "1"}}
        rows= {data.song}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[3]}
        
        autoHeight
      />

<div>

<Snackbar style={{marginTop: "50px"}}
    anchorOrigin={{vertical: "top", horizontal: "right"}}
    open={open} 
    autoHideDuration={3000} 
    onClose={handleClose}>
  <Alert 
    onClose={handleClose} 
    severity="error" 
    sx={{ width: '100%' }}>
Scheinbar ist etwas schief gegangen!  </Alert>
</Snackbar>
</div>
<div>
<Snackbar style={{marginTop: "50px"}}
    anchorOrigin={{vertical: "top", horizontal: "right"}}
    open={openSong} 
    autoHideDuration={3000} 
    onClose={handleCloseSong}>
  <Alert 
    onClose={handleCloseSong} 
    severity="success" 
    sx={{ width: '100%' }}>
   Song wurde erfolgreich gelöscht...
  </Alert>
</Snackbar>
</div>
    </div>
 
  );
}

export default SongList;