import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { NhostApolloProvider } from "@nhost/react-apollo";
import { NhostAuthProvider } from "@nhost/react-auth";
import { auth } from "utils/nhost";
import { Login } from "components/login/login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthGate } from "components/auth-gate";
import Users from './components/users/users';
import Songs from './components/songs/songs';
import Header from './components/header/header';
import IconMenu from './components/nav/nav';
// import Files from './components/file/file';
import 'bootstrap/dist/css/bootstrap.min.css';
import TotalCustomers from './components/cards/TotalCustomers';
import AddSongs from './components/add_song/add_song';
import NewsList from './components/query_news/query_news';
import AddNews from './components/add_news/add_news';
ReactDOM.render(
  <React.StrictMode>
     
    <NhostAuthProvider auth={auth}>
      <NhostApolloProvider
        auth={auth}
        gqlEndpoint="https://hasura-03655200.nhost.app/v1/graphql"
      >
    
       <Router>
           
            <Switch>
           
            <Route exact path="/login">
              <Login />
            </Route>
            
            <AuthGate>
              <Route exact path="/">
              <Header />
              <IconMenu />
              {/* <Files /> */}
              <TotalCustomers />

                <App />
              </Route>
              <Route exact path="/users">
              <Header />
              <IconMenu />
              <Users />
              </Route>
              <Route exact path="/songs">
              <Header />
              <IconMenu />
              <AddSongs />
              <Songs />
              </Route>
              <Route exact path="/news">
              <Header />
              <IconMenu />
              <AddNews />
              <NewsList />
              </Route>
            </AuthGate>
          </Switch>
        </Router>
      </NhostApolloProvider>
    </NhostAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);