import React, {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {Formik} from 'formik'
import * as yup from 'yup';
import { storage } from "utils/nhost";
import { gql, useMutation, useSubscription } from "@apollo/client";
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import './add_song.css';
import CircularProgress from '@mui/material/CircularProgress';

// import Button1 from '@mui/material/Button';

const schema = yup.object().shape({
  songName: yup.string().required(),
  songDur: yup.string().required(),
  songDes: yup.string().required(),

  file: yup.mixed().required(),
  terms: yup.bool().required().oneOf([true], 'terms must be accepted'),
});


const INSERT_SONG = gql`
  mutation($song_name: song_insert_input!) {
    insert_song(objects: [$song_name]) {
      affected_rows
    }
  }
`;
const GET_CATEGORY = gql`
  subscription {
    category {
      id
      title
    }
   
  }

`;


export default function AddSong() {


    const [songName, setSongName] = useState('');
    const [songDuration, setDuration] = useState('');
    const [songDescript, setDescript] = useState('');
    const [addCategory, setCategory] = useState('');
    const {data, loading} = useSubscription(GET_CATEGORY);
    
    const [storeSong, setSong] = useState(null);
    const [storeCover, setCover] = useState(null);

    const [uploadSong] = useMutation(INSERT_SONG);
    const [uploadCompleted, setUploadCompleted] = useState(0);
    const [uploadState, setUploadState] = useState("");
    

    // rerender UI every 5 sec to update 'created at' column
    const [time, setTime] = useState(Date.now());
  console.log(time);
    useEffect(() => {
      const interval = setInterval(() => setTime(Date.now()), 1000);
      return () => {
        clearInterval(interval);
      };
    }, []);

    function addSong(e) {
      setSong(e.target.files[0]);
    }
    function refreshPage() {
      window.location.reload(false);
    }
    
    function addCover(e) {
      setCover(e.target.files[0]);
    }
    
    const [open, setOpen] = useState(false);
    const [openSong, setOpenSong] = useState(false);
  
  
    const handleClickSong = () => {
      setOpenSong(true);
    };

  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
    const handleCloseSong = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSong(false);
    };
    async function upload(e) {
      e.preventDefault();

      try {
         await uploadSong({
          variables: {
            song_name: {
              title: songName,
              duration: songDuration,
              artwork: `https://backend-03655200.nhost.app/storage/o/songs/${storeCover.name}`,
              url: `https://backend-03655200.nhost.app/storage/o/songs/${storeSong.name}`,
              storage_url: `/songs/${storeSong.name}`,
              storage_artwork: `/songs/${storeCover.name}`,
              artist: songDescript,
              song_category: addCategory,
            },
        
          },
        });
          
            await setUploadState('UPLOADING');
            await storage.put(`/songs/${storeSong.name}`, storeSong, null, (d) => {
              setUploadCompleted((d.loaded / d.total) * 100);});
            await storage.put(`/songs/${storeCover.name}`, storeCover);
        


      } catch (error) {
        console.log({error});
        return alert('something went wrong!!')

      }
      setCategory('');
      setCover(null);
      setSong(null);
      setDescript('');
      setDuration('');
      setSongName('');
      setCategory('');
      setSongName('');
      setUploadState('');
      addButtonFunc();
      handleClickSong();
      refreshPage();


    }

    const [addForm, setFormDisplay] = useState('none');
    const [addButton, setButtonDisplay] = useState('block');

    let valueForm = 'block';
    if (loading) {
      return <div style={{position: "absolute", top: "50%", left: "50%" , transform: "translate(-50%, -50%)", display: "none"}}><CircularProgress />
      </div>;
    }
    
    function addFormFunc() {
      if ('none') {
        setFormDisplay(valueForm);
        setButtonDisplay('none');

      } else {
        console.log('nothing to do here');
      }
    }
    function addButtonFunc() {
      if ('none') {
        setButtonDisplay('block');
        setFormDisplay('none');
      } else {
        console.log('nothing to do here');
      }
    }

    function addButtonFuncReload() {
      if ('none') {
        setButtonDisplay('block');
        setFormDisplay('none');
        refreshPage();
      } else {
        console.log('nothing to do here');
      }
    }
    
      return (
        <div >
      
      <Fab color="primary" 
          aria-label="add" 
          style={{position: "fixed", right: "80px", display: `${addButton}`, zIndex: "2"}}
          onClick={addFormFunc}


    >
        <AddIcon />
      </Fab>
      <div>

            <Snackbar style={{marginTop: "50px"}}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={open} 
                autoHideDuration={3000} 
                onClose={handleClose}>
              <Alert 
                onClose={handleClose} 
                severity="success" 
                sx={{ width: '100%' }}>
               Song wurde erfolgreich gelöscht...
              </Alert>
            </Snackbar>
         </div>
         <div>
            <Snackbar style={{marginTop: "50px"}}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={openSong} 
                autoHideDuration={3000} 
                onClose={handleCloseSong}>
              <Alert 
                onClose={handleCloseSong} 
                severity="success" 
                sx={{ width: '100%' }}>
               Song wurde erfolgreich angelegt...
              </Alert>
            </Snackbar>
         </div>
        <Formik
       
     
          validationSchema={schema}
          onSubmit={console.log}
          initialValues={{
            songName: '',
            songDuration: '',
            songDescription: '',
            city: '',
         
            file: null,
            terms: false,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form className="addForm" noValidate   style={{display: `${addForm}`, width: "800px", marginLeft: "350px", marginTop: "100px"}}>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label>Song Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="songName"
                    value={songName}
                    onChange={(e) => setSongName(e.target.value)}
                    isValid={touched.songName && !errors.songName}
                  />
                  <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationFormik102"
                  className="position-relative"
                >
                  <Form.Label>Song Duration</Form.Label>
                  <Form.Control
                    type="text"
                    name="songDur"
                    value={songDuration}
                    onChange={(e) => setDuration(e.target.value)}
                    isValid={touched.songDur && !errors.songDur}
                  />
    
                  <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                </Form.Group>
               
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="validationFormik103"
                  className="position-relative"
                >
                  <Form.Label>Artist</Form.Label>
                  <Form.Control
                    type="text"
                    name="songDes"
                    value={songDescript}
                    onChange={(e) => setDescript(e.target.value)}
                    isInvalid={!!errors.city}
                  />
    
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
                
               
              </Row>
              <Form.Label>Kategorie</Form.Label>
              {!data ? 'no data' : <Form.Select aria-label="Default select example" 
              className="position-relative" 
              size="sm" 
              style={{marginBottom: "20px"}}
              onChange={(e) => setCategory(e.target.value)}
              value={addCategory}
>
              <option>Wähle eine Kategorie</option>
              {data.category.map((item) => {
                  return <option key={item.id} value={item.id}>{item.title}</option>
                })}

            
</Form.Select>
               

              }     
              
              <Form.Group className="position-relative mb-3">
                <Form.Label>Song File</Form.Label>
                <Form.Control
                  type="file"
                  required
                  name="file"
                  onChange={addSong}
                  isInvalid={!!errors.file}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.file}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group className="position-relative mb-3">
              <label htmlFor="contained-button-file" style={{display: "block", marginBottom: "20px"}}>
        <Input  id="contained-button-file"  type="file" name="file" onChange={addSong} />
        <Button variant="contained"  component="span">
          Upload Song
        </Button>

      </label>
      </Form.Group>
      <Form.Group className="position-relative mb-3">

      <label htmlFor="contained-button-file" style={{display: "block",  marginBottom: "20px"}}>
        <Input  id="contained-button-file"  type="file" name="file" onChange={addCover} />
        <Button variant="contained"  component="span">
          Upload Cover
        </Button>

      </label>
      </Form.Group> */}

              <Form.Group className="position-relative mb-3">
                <Form.Label>Song Cover</Form.Label>
                <Form.Control
                  type="file"
                  required
                  name="file"
                  onChange={addCover}
                  isInvalid={!!errors.file}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.file}
                </Form.Control.Feedback>
              </Form.Group>
              {uploadState === "UPLOADING" && (
                  <div className="uploading-progress" >
                    {/* {uploadCompleted} % */}
                    <LinearProgress  style={{marginTop: "20px", marginBottom: "20px"}} variant="determinate" value={uploadCompleted} />
                  </div>
                )}
              <Button  onClick={upload} >Speichern</Button>
              <Button style={{marginLeft: "20px", backgroundColor: "red", border: "none"}} onClick={addButtonFuncReload} >abbrechen</Button>

            </Form>
               
          )}
        </Formik>
    
        </div>
      );
    }
    
